export const handleTouchStart = (e, setTouchInfo) => {
  const touch = e.touches[0];
  setTouchInfo((prevState) => ({
    ...prevState,
    startX: touch.clientX,
    startY: touch.clientY,
  }));
};

export const handleTouchMove = (e, setTouchInfo) => {
  const touch = e.touches[0];
  setTouchInfo((prevState) => ({
    ...prevState,
    endX: touch.clientX,
    endY: touch.clientY,
  }));
};

export const handleTouchEnd = (
  touchInfo,
  photoId,
  setPhotoId,
  nextPhoto,
  prevPhoto
) => {
  const diff = touchInfo.endX - touchInfo.startX;
  //   console.log("Touch diff:", diff);
  if (diff < -50) {
    nextPhoto();
  }
  if (diff > 50) {
    if (photoId > 0) {
      prevPhoto();
    }
  }
};

export const handleTap = (divRef, isFullscreen, setIsFullscreen) => {
  if (!isFullscreen) {
    if (divRef.current.requestFullscreen) {
      divRef.current.requestFullscreen();
    } else if (divRef.current.mozRequestFullScreen) {
      // Firefox
      divRef.current.mozRequestFullScreen();
    } else if (divRef.current.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      divRef.current.webkitRequestFullscreen();
    } else if (divRef.current.msRequestFullscreen) {
      // IE/Edge
      divRef.current.msRequestFullscreen();
    }
    setIsFullscreen(true);
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      // Chrome, Safari, and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }
    setIsFullscreen(false);
  }
};
