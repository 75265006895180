import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import fototest from "../images/fototest.jpg";
// import MapGpx from './MapGpx';
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Link from "@mui/material/Link";
// import PermMediaIcon from '@mui/icons-material/PermMedia';
import gmicon from "../gmicon.png";
import { CardActionArea } from "@mui/material";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton for placeholder

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardModuleMulti({
  cardTitle,
  tripDate,
  cardCover,
  difficulty,
  distance,
  heightGain,
  trackLink,
  imageUrl,
  setCardId,
  cardId,
  setPhotoId,
  setDisplayOption,
  displayOption,
  setMapId,
  tripInfo,
  startPoint,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMapClick = () => {
    setCardId(cardId);
    setPhotoId(0);
    // setDisplayOption("map");
    if ((displayOption = "map")) {
      // setMapId(cardId);
    }
  };

  const handlePhotoClick = () => {
    setCardId(cardId);
    setPhotoId(0);
    // setDisplayOption("gallery");
    if (displayOption === "map") {
      setMapId(cardId);
    }
  };

  const [imageLoaded, setImageLoaded] = React.useState(false); // Track image loading state

  const handleImageLoad = () => {
    setImageLoaded(true); // Set imageLoaded to true when the image is fully loaded
  };

  return (
    <Card sx={{ width: { xs: 250, md: 250, xl: 300 } }}>
      <CardActionArea onClick={handlePhotoClick}>
        <CardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: red[500],
                display: { xs: "none", md: "none", xl: "flex" },
              }}
              aria-label="recipe"
            >
              MTB
            </Avatar>
          }
          // action={
          //   <IconButton
          //     aria-label="settings"
          //     onClick={handleMapClick}
          //     sx={{ display: { xs: "none", md: "none", xl: "flex" } }}
          //   >
          //     <img id="gmicon" src={gmicon} alt="gmicon.png" />
          //   </IconButton>
          // }
          // title="MTB trip to Refugii Biella"
          title={cardTitle}
          subheader={tripDate}
        />

        {/* Aspect Ratio Placeholder */}
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingTop: "56.25%", // 16:9 aspect ratio (adjust as needed)
            backgroundColor: "#f0f0f0", // Placeholder background color
          }}
        >
          {!imageLoaded && (
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          )}
          <CardMedia
            component="img"
            image={imageUrl}
            alt={cardTitle}
            onLoad={handleImageLoad} // Trigger when the image is loaded
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensure the image covers the placeholder
              display: imageLoaded ? "block" : "none", // Hide the image until it's loaded
            }}
          />
        </div>
      </CardActionArea>

      <CardContent sx={{ flexGrow: 1, display: { xs: "none", md: "block" } }}>
        <Typography variant="body2" color="text.secondary">
          {difficulty}
        </Typography>
        <Typography variant="body2" color="text.secondary"></Typography>

        <Typography variant="body2" color="text.secondary">
          Start point: {startPoint}
        </Typography>
      </CardContent>

      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <CompareArrowsIcon />
        </IconButton>
        <Typography variant="body2" color="text.secondary">
          {distance} km
        </Typography>

        <IconButton aria-label="share">
          <TrendingUpIcon />
        </IconButton>
        <Typography variant="body2" color="text.secondary">
          {heightGain} m
        </Typography>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {tripInfo}
        <br></br>
        <br></br>
      </Collapse>
    </Card>
  );
}
