import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.gridlayer.googlemutant";
import "leaflet.fullscreen/Control.FullScreen.css";
import "leaflet.fullscreen";
import "leaflet-arrowheads"; // Import the leaflet-arrowheads plugin
import Typography from "@mui/material/Typography";
import locationControl from "./LocationControl"; // Import the location control
import loadGoogleMapsApi from "../loadGoogleMapsApi"; // Import the utility function

function MapModule({
  polygon,
  mapRef,
  showManyTracks,
  trips,
  setTripId,
  currentTrip,
  setChooseByMap,
}) {
  const polylineRefs = useRef([]);
  const SinglePolylineOptions = {
    color: "#FF0000",
    opacity: 0.8,
    weight: 4, // Change this value to adjust the thickness of the polyline
  };

  const MultiPolylineOptions = {
    color: "#FF0000",
    opacity: 0.8,
    weight: 2, // Change this value to adjust the thickness of the polyline
  };

  const hitArealineOptions = {
    color: "transparent",
    opacity: 0.8,
    weight: 15, // Change this value to adjust the thickness of the polyline
  };

  const polylinesArray = trips.map((item) => item.attributes.polygon.polyline);

  const currentMarkerRef = useRef(null); // Ref to store the current marker

  useEffect(() => {
    const apiKey = process.env.REACT_APP_googleMapsApiKey;

    loadGoogleMapsApi(apiKey)
      .then(() => {
        if (!mapRef.current) {
          const mapInstance = L.map("map", {
            zoom: 5,
            zoomControl: false, // Disable the default zoom control
          });

          // Add the zoom control manually to the top left
          L.control.zoom({ position: "topleft" }).addTo(mapInstance);

          const baseLayer = L.tileLayer(
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            {
              attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }
          );

          const cycleLayer = L.tileLayer(
            "https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=2c5d83196b3148c59c52080e1efd0d27",
            {
              attribution:
                '&copy; <a href="http://www.opencyclemap.org">OpenCycleMap</a>',
            }
          );

          const googleRoadmap = L.gridLayer.googleMutant({
            type: "roadmap", // can be 'roadmap', 'satellite', 'terrain', 'hybrid'
          });

          const googleSatellite = L.gridLayer.googleMutant({
            type: "satellite",
          });

          const googleTerrain = L.gridLayer.googleMutant({
            type: "terrain",
          });

          baseLayer.addTo(mapInstance);
          mapRef.current = mapInstance;

          mapInstance.on("zoomend", () => {
            const zoomLevel = mapInstance.getZoom();
          });

          const baseLayers = {
            "OpenStreetMap Base Layer": baseLayer,
            "OpenCycleMap Cycle Layer": cycleLayer,
            "Google Roadmap": googleRoadmap,
            "Google Satellite": googleSatellite,
            "Google Terrain": googleTerrain,
          };

          L.control.layers(baseLayers).addTo(mapInstance);

          L.control.fullscreen({ position: "topleft" }).addTo(mapInstance);

          mapInstance.addControl(new locationControl());

          // Add event listener for window resize
          window.addEventListener("resize", () => {
            mapInstance.invalidateSize();
          });
        }

        // Remove existing polylines
        polylineRefs.current.forEach((polyline) => {
          if (mapRef.current.hasLayer(polyline)) {
            mapRef.current.removeLayer(polyline);
          }
        });
        polylineRefs.current = [];

        if (showManyTracks) {
          // Draw all polylines from polylinesArray
          const bounds = L.latLngBounds();
          polylinesArray.forEach((polyline, index) => {
            const transformedPolyline = polyline.map((point) => [
              point.lat,
              point.lng,
            ]);
            const polylineLayer = L.polyline(
              transformedPolyline,
              MultiPolylineOptions
            ).addTo(mapRef.current);

            polylineRefs.current.push(polylineLayer);
            bounds.extend(polylineLayer.getBounds());

            // Add the hit area polyline
            const hitAreaPolyline = L.polyline(
              transformedPolyline,
              hitArealineOptions
            ).addTo(mapRef.current);
            hitAreaPolyline.on("click", () => {
              onPolylineClicked(index);
            });
          });
          // mapRef.current.fitBounds(bounds);
        }

        // Current polyline
        if (polygon && polygon.polyline) {
          const transformedPolyline = polygon.polyline.map((point) => [
            point.lat,
            point.lng,
          ]);

          // Add the polyline to the map
          const polyline = L.polyline(
            transformedPolyline,
            SinglePolylineOptions
          ).addTo(mapRef.current);

          // Bind a pop-up to the polyline
          polyline
            .bindPopup(
              `Distance: ${currentTrip.attributes.distance} km,<br> height gain: ${currentTrip.attributes.heightGain} m`
            )
            .openPopup();

          // Remove the existing marker if it exists
          if (currentMarkerRef.current) {
            mapRef.current.removeLayer(currentMarkerRef.current);
          }

          // Add a marker at the start point of the polyline
          const startPoint = transformedPolyline[0]; // First point of the polyline
          const startIcon = L.icon({
            iconUrl: "./start.png", // Replace with your icon URL
            iconSize: [20, 20], // Size of the icon
            iconAnchor: [5, 5], // Anchor point of the icon
          });

          const startMarker = L.marker(startPoint, {
            icon: startIcon,
            title: "Start Point",
          }).addTo(mapRef.current);

          // Store the marker in the ref
          currentMarkerRef.current = startMarker;

          // Add arrowheads to the current polyline
          const updateArrowheads = () => {
            const zoomLevel = mapRef.current.getZoom();
            polyline.arrowheads({
              size: "10px",
              frequency: zoomLevel > 11 ? 15 : 0, // Conditional frequency based on zoom level
              offset: "-7%", // Start arrows 10% along the polyline
              fill: true,
              color: "#0000FF",
            });
          };

          // Initial arrowheads setup
          updateArrowheads();

          // Add event listener to update arrowheads on zoom change
          mapRef.current.on("zoomend", updateArrowheads);

          polylineRefs.current.push(polyline);
          mapRef.current.fitBounds(polyline.getBounds());
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });

    // Cleanup event listener on component unmount

    const findCardId = (index) => {
      const card = trips.find((_, idx) => idx === index);
      return card ? card.id : null;
    };

    const onPolylineClicked = (index) => {
      setTripId(findCardId(index));
      setChooseByMap(true);
    };

    return () => {
      if (mapRef.current) {
        window.removeEventListener("resize", () => {
          mapRef.current.invalidateSize();
        });
      }
    };
  }, [polygon, mapRef, showManyTracks, polylinesArray]);

  return (
    <div
      id="map"
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        backgroundColor: "black",
        width: "100%",
      }}
    >
      <Typography variant="h5" color="white" sx={{ mt: 1 }}>
        {/* {needRefresh.current.toString()} */}
        {/* mapId = {mapId}, cardId = {cardId} */}
      </Typography>
    </div>
  );
}

export default React.memo(MapModule);
