import L from "leaflet";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";

// Create a custom icon
const customIcon = L.icon({
  iconUrl: iconUrl,
  iconRetinaUrl: iconRetinaUrl,
  shadowUrl: shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const locationControl = L.Control.extend({
  options: {
    position: "bottomright",
  },
  onAdd: function (map) {
    const container = L.DomUtil.create(
      "div",
      "leaflet-bar leaflet-control leaflet-control-custom"
    );

    const controlIcon = L.DomUtil.create("img", "", container);
    controlIcon.src = iconUrl; // Use the default marker icon
    controlIcon.style.width = "25px";
    controlIcon.style.height = "41px";

    container.style.backgroundColor = "white";
    container.style.width = "30px";
    container.style.height = "47px";
    container.style.cursor = "pointer";

    container.onclick = function () {
      // Trigger the location check
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLat = position.coords.latitude;
            const userLng = position.coords.longitude;
            const userMarker = L.marker([userLat, userLng], {
              icon: customIcon,
            }).addTo(map);
            userMarker.bindPopup("You are here").openPopup();
            map.setView([userLat, userLng], 13);
          },
          (error) => {
            console.error("Error getting user's location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    return container;
  },
});

export default locationControl;
