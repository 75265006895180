import CardModuleMulti from "../components/CardModuleMulti";
import { useState, useEffect, useRef } from "react";
import api from "../api.js";
import MapModule from "../components/MapModule";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import Buttons from "../components/Buttons";
import CustomizedDialogs from "../components/DownloadAlert";
import CustomizedDialogsInfo from "../components/InfoAlert";
import CustomizedDialogsFilter from "../components/FilterAlert";
import {
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  handleTap,
} from "../utils/touchHandlers";
import { handleScroll, sortTripGallery, renderCard } from "../utils/utils";

// Start building touch events

const API_URL = process.env.REACT_APP_API;

export default function Explore() {
  const [tripId, setTripId] = useState(null);
  const [photoId, setPhotoId] = useState(0);
  const [displayOption, setDisplayOption] = useState("map");
  const [openGpxDialog, setOpenGpxDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [filterOption, setFilterOption] = useState("All");
  const [trips, setTrips] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false); //for full screen
  const [touchInfo, setTouchInfo] = useState({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showManyTracks, setShowManyTracks] = useState(false);
  const [highPagination, setHighPagination] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [chooseByMap, setChooseByMap] = useState(false);

  // const [page, setPage] = useState(1);

  // Lets change setPage to useRef
  const refPage = useRef(1);

  const ref = useRef(null);

  const divRef = useRef(null); //for full screen

  const [scrollTop, setScrollTop] = useState(0);

  const [polygon, setPolygon] = useState(null);

  const mapRef = useRef(null); // Reference to the map instance

  // useEffect prevents from unwanted repetitions of readCards function with the same page
  useEffect(() => {
    readCards();
  }, [refPage.current]);

  useEffect(() => {
    if (tripId !== null) {
      setPhotoId(0);
      const filteredCard = trips.filter((card) => card.id === tripId)[0];
      if (filteredCard) {
        setPolygon(filteredCard.attributes.polygon);
        setCurrentTrip(filteredCard);
      }
    }
  }, [tripId]);

  useEffect(() => {
    if (tripId !== null) {
      refPage.current = 1; //added
      setTrips([]); //added
      readCards();
      setPhotoId(0);
      setTripId(0);
      setCurrentTrip(trips.find((trip) => trip.id === tripId));
      setDisplayOption("gallery");
    }
  }, [filterOption, pageSize]);

  useEffect(() => {
    if (highPagination) {
      setPageSize(200);
    } else {
      setPageSize(20);
    }
  }, [highPagination]);

  useEffect(() => {
    if (displayOption === "map" && mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, [displayOption]);

  const readCards = async () => {
    setIsLoading(true);
    setError(null);

    const filtrationString =
      filterOption === "All" ? "" : "&filters[trackLink][$eq]=" + filterOption;
    const string =
      "/api/cards?populate=*&sort=tripDate" +
      filtrationString +
      `&pagination[page]=${refPage.current}&pagination[pageSize]=${pageSize}`;

    const res = await api
      .get(string)
      .then((res) => {
        const data = res.data.data;
        setTrips((prevItems) => [...prevItems, ...data]);

        if (refPage.current === 1) {
          setTripId(data[0].id);
          setPolygon(data[0].attributes.polygon);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleScrollEvent = (event) => {
    handleScroll(
      event,
      setScrollTop,
      scrollTop,
      ref,
      isLoading,
      setIsLoading,
      refPage
    );
  };

  if (!currentTrip) return "loading...";

  const gpxFileUrl =
    API_URL + currentTrip.attributes.gpxtrack.data[0].attributes.url;

  const tripGallery = currentTrip.attributes.gallery.data;

  sortTripGallery(tripGallery);

  const nextPhoto = () => {
    if (photoId < tripGallery.length - 1) {
      setPhotoId(photoId + 1);
      setImgLoaded(true);
    }
  };

  const prevPhoto = () => {
    if (photoId > 0) {
      setPhotoId(photoId - 1);
      setImgLoaded(false);
    }
  };

  return (
    <div className="responsiveOuterBox">
      <div
        className="responsiveCardBox"
        id="box"
        ref={ref}
        onScroll={handleScrollEvent}
      >
        {chooseByMap && currentTrip ? (
          <>
            {renderCard(
              currentTrip,
              setTripId,
              setPhotoId,
              setDisplayOption,
              setTripId,
              displayOption
            )}
            {/* Add the button below the current card */}
            <Button
              size="large"
              variant="outlined"
              onClick={() => {
                setChooseByMap(false);
              }}
              style={{ marginTop: "10px" }}
              sx={{
                color: "white",
              }}
            >
              Show more cards
            </Button>
          </>
        ) : (
          trips.map((trip) =>
            renderCard(
              trip,
              setTripId,
              setPhotoId,
              setDisplayOption,
              setTripId,
              displayOption
            )
          )
        )}
      </div>

      <div className="responsiveSideBox">
        <div
          style={{
            minHeight: "40px",
          }}
        >
          <Typography variant="h5" color="white" sx={{ mt: 1 }}>
            {currentTrip.attributes.cardTitle}
          </Typography>
        </div>

        {polygon && (
          <div
            className="mapBox"
            style={{
              display: displayOption === "map" ? "flex" : "none",
              flexGrow: "1",
              backgroundColor: "red",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <MapModule
              polygon={polygon}
              mapRef={mapRef}
              trips={trips}
              showManyTracks={showManyTracks}
              setTripId={setTripId}
              setChooseByMap={setChooseByMap}
              currentTrip={currentTrip}
            />
          </div>
        )}

        <div
          style={{
            className: "galleryBox",
            display: displayOption === "gallery" ? "flex" : "none",
            flexDirection: "column",
            flexGrow: "1",
          }}
        >
          <div
            className="newGalleryContainer"
            ref={divRef}
            onClick={() => handleTap(divRef, isFullscreen, setIsFullscreen)}
            onTouchStart={(e) => handleTouchStart(e, setTouchInfo)}
            onTouchMove={(e) => handleTouchMove(e, setTouchInfo)}
            onTouchEnd={() =>
              handleTouchEnd(
                touchInfo,
                photoId,
                setPhotoId,
                nextPhoto,
                prevPhoto
              )
            }
            style={{
              backgroundImage:
                'url("' +
                API_URL +
                tripGallery[photoId].attributes.formats.xlarge.url +
                '")',
              flexGrow: "1",
            }}
          ></div>

          {imgLoaded && photoId < tripGallery.length - 1 && (
            <img
              className="preload-img"
              src={
                API_URL + tripGallery[photoId + 1].attributes.formats.xlarge.url
              }
              alt="Italian Trulli"
            ></img>
          )}
          <div
            className="arrowsBox"
            style={{
              display: "flex",
              flexwrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "2px",
            }}
          >
            <Button
              size="large"
              variant="outlined"
              startIcon={<ArrowBackIosIcon />}
              sx={{ color: "white" }}
              onClick={prevPhoto}
              disabled={photoId < 1}
            >
              BACK
            </Button>

            <Button
              size="large"
              variant="outlined"
              sx={{
                color: "gray",
              }}
            >
              {photoId + 1} / {tripGallery.length}
            </Button>

            <Button
              size="large"
              variant="outlined"
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                color: "white",
              }}
              onClick={nextPhoto}
              disabled={photoId >= tripGallery.length - 1}
            >
              NEXT
            </Button>
          </div>
        </div>

        <Buttons
          setDisplayOption={setDisplayOption}
          setOpenGpxDialog={setOpenGpxDialog}
          setOpenInfoDialog={setOpenInfoDialog}
          setOpenFilterDialog={setOpenFilterDialog}
          setFilterOption={setFilterOption}
          filterOption={filterOption}
        />

        {openGpxDialog && (
          <CustomizedDialogs
            openGpxDialog={openGpxDialog}
            setOpenGpxDialog={setOpenGpxDialog}
            gpxFileUrl={gpxFileUrl}
            cardTitle={currentTrip.attributes.cardTitle}
          />
        )}

        {openInfoDialog && (
          <CustomizedDialogsInfo
            openInfoDialog={openInfoDialog}
            setOpenInfoDialog={setOpenInfoDialog}
            currentTrip={currentTrip}
          />
        )}

        {openFilterDialog && (
          <CustomizedDialogsFilter
            openFilterDialog={openFilterDialog}
            setOpenFilterDialog={setOpenFilterDialog}
            setFilterOption={setFilterOption}
            filterOption={filterOption}
            setShowManyTracks={setShowManyTracks}
            showManyTracks={showManyTracks}
            highPagination={highPagination}
            setHighPagination={setHighPagination}
          />
        )}
      </div>
    </div>
  );
}
