import { useState, useEffect } from "react";
import api from "../api.js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const API_URL = process.env.REACT_APP_API;

export default function About() {
  const [aboutContent, setAboutContent] = useState();

  useEffect(() => {
    helloFromServer();
  }, []);

  const helloFromServer = async () => {
    let res = await api
      .get("/api/about?populate=*")
      .then((res) => setAboutContent(res.data.data))
      .catch((error) => {
        console.log(error);
      });
  };

  if (!aboutContent) return "loading...";
  console.log(aboutContent);

  return (
    <div
      className="aboutBiggestDiv"
      style={{
        overflow: "hidden",
      }}
    >
      <div className="aboutTextDiv" style={{}}>
        <Typography
          variant="h6"
          color="white"
          sx={{
            mt: { xs: 3 },
            textAlign: "left",
            fontSize: { xs: "0.9rem", lg: "1.25rem" },
          }}
        >
          RELEASE DATE: 2025-04-01 21:00
        </Typography>

        <Typography
          variant="h6"
          color="white"
          sx={{
            mt: { xs: 2 },
            textAlign: "justify",
            fontSize: { xs: "0.9rem", lg: "1.25rem" },
          }}
        >
          A few years ago my adventure with mountain biking started. Since then
          I visited many mountain ranges, mostly in Poland which is my home
          country.{" "}
        </Typography>

        <Typography
          variant="h6"
          color="white"
          sx={{
            mt: { xs: 2 },
            textAlign: "justify",
            fontSize: { xs: "0.9rem", lg: "1.25rem" },
          }}
        >
          I created this page for enthusiasts of mountain biking needding some
          support for planning rides.
        </Typography>

        <Typography
          variant="h6"
          color="white"
          sx={{
            mt: { xs: 2 },
            textAlign: "justify",
            fontSize: { xs: "0.9rem", lg: "1.25rem" },
          }}
        >
          {/* I share my trips done in 2022 during two wonderful weeks in Dolomites.
          They are located in two regions: Corvara and Cortina d'Ampezzo */}
        </Typography>

        <Typography
          variant="h6"
          color="white"
          sx={{
            mt: { lg: 2 },
            textAlign: "justify",
            fontSize: { xs: "0.9rem", lg: "1.25rem" },
          }}
        >
          Explore and have fun!
        </Typography>

        <Typography
          variant="h6"
          color="white"
          sx={{
            my: { xs: 2 },
            textAlign: "left",
            fontSize: { xs: "0.9rem", lg: "1.25rem" },
          }}
        >
          Piotr Sarzyński
        </Typography>
      </div>

      <Box
        component="img"
        sx={{
          maxWidth: "40%",
          minWidth: "300px",
          overflow: "hidden",
        }}
        src={
          API_URL +
          // "http://54.37.234.46:1337" +
          // "http://localhost:1337" +
          // "https://strapi-for-dolomites.onrender.com" +
          aboutContent.attributes.author.data.attributes.formats.medium.url
        }
        alt="Paella dish"
      />
    </div>
  );
}
