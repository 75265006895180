import CardModuleMulti from "../components/CardModuleMulti";

export const handleScroll = (
  event,
  setScrollTop,
  scrollTop,
  ref,
  isLoading,
  setIsLoading,
  refPage
) => {
  setScrollTop(event.currentTarget.scrollTop);

  if (
    scrollTop + ref.current.offsetHeight < 0.95 * ref.current.scrollHeight ||
    isLoading
  )
    return;

  setIsLoading(true);
  refPage.current += 1;
};

export const sortTripGallery = (tripGallery) => {
  tripGallery.sort((a, b) => {
    const nameA = a.attributes.name;
    const nameB = b.attributes.name;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const renderCard = (
  trip,
  setTripId,
  setPhotoId,
  setDisplayOption,
  setMapId,
  displayOption
) => {
  const attributes = trip.attributes;
  const API_URL = process.env.REACT_APP_API;

  return (
    <div key={trip.id}>
      <CardModuleMulti
        cardTitle={attributes.cardTitle}
        tripDate={attributes.tripDate}
        cardCover={attributes.cardCover}
        difficulty={attributes.difficulty}
        distance={attributes.distance}
        heightGain={attributes.heightGain}
        trackLink={attributes.trackLink}
        imageUrl={
          API_URL + attributes.cardCover.data.attributes.formats.medium.url
        }
        setCardId={setTripId}
        cardId={trip.id}
        setPhotoId={setPhotoId}
        setDisplayOption={setDisplayOption}
        displayOption={displayOption}
        setMapId={setMapId}
        tripInfo={attributes.tripInfo}
        startPoint={attributes.startPoint}
      />
    </div>
  );
};
