import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Box } from "@mui/system";

export default function BasicButtonGroup({
  setDisplayOption,
  setOpenGpxDialog,
  setOpenInfoDialog,
  setOpenFilterDialog,
}) {
  // console.log("gpxfileurl: " + { gpxFileUrl });

  return (
    <Box
      sx={{
        mt: "10px",
        color: "white",
      }}
    >
      <ButtonGroup variant="outlined">
        <Button
          sx={{
            color: "lightgray",
            pl: "12px",
            pr: "12px",
          }}
          onClick={() => setDisplayOption("gallery")}
        >
          PHOTO
        </Button>
        <Button
          sx={{
            color: "lightgray",
            pl: "12px",
            pr: "12px",
          }}
          onClick={() => {
            setDisplayOption("map");
          }}
        >
          MAP
        </Button>
        <Button
          sx={{
            color: "lightgray",
            pl: "12px",
            pr: "12px",
          }}
          onClick={() => setOpenInfoDialog(true)}
        >
          INFO
        </Button>
        <Button
          sx={{
            color: "lightgray",
            pl: "12px",
            pr: "12px",
          }}
          onClick={() => setOpenGpxDialog(true)}
        >
          GPX
        </Button>
        <Button
          sx={{
            color: "lightgray",
            pl: "12px",
            pr: "12px",
          }}
          onClick={() => setOpenFilterDialog(true)}
        >
          FILTER
        </Button>
      </ButtonGroup>
    </Box>
  );
}
